@import "../../../styles/variables.scss";

.row {
  display: flex;
  flex-direction: row;
  margin-left: -15px;
  margin-right: -15px;
  padding-bottom: 30px;
}

.column {
  display: flex;
  flex-direction: column-reverse;
  flex-basis: 100%;
  flex: 1;
  margin: 0 15px;
  position: relative;
  overflow: hidden;

  @include laptop {
    flex-direction: row;
  }
}

.tile {
  border: 2px solid#ffffff;
  background-color: #e5dcd3;
  min-height: 170px;

  @include tablet {
    min-height: 240px;
  }
}

.left {
  position: relative;
  flex: 1;

  @include laptop {
    width: 50%;
  }

  a {
    text-decoration: none;
  }
}

.leftContent {
  margin-inline-start: 15px;
  margin-top: 15px;

  @include laptop {
    margin-top: 40px;
    margin-inline-start: 20px;
    margin-inline-end: 50px;
  }
}

.header {
  font-size: 22px;
  font-weight: 600;
  font-family: Poppins;
  margin: 0;
  color: #002238;

  @include not-mobile {
    font-size: 32px;
  }
}

.bodyText {
  text-overflow: ellipsis;
  overflow: hidden;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  word-wrap: break-word;
  font-size: 14px;
  display: none;
  font-family: Poppins;

  @include laptop {
    display: inline;
    display: -webkit-box;
  }
}

.right {
  position: relative;
  flex: 1;

  @include laptop {
    width: 50%;
  }
}

.image {
  @include laptop {
    height: 100%;
  }
}

.link {
  font-size: 11px;
  letter-spacing: 1.32px;
  color: #002238;
  cursor: pointer;
  font-weight: 900;
  padding-bottom: 15px;
  display: inline-block;

  @include not-mobile {
    font-size: 13px;
    letter-spacing: 1.56px;
    margin-top: 15px;
    padding-top: 15px;
  }

  .additionalText {
    position: relative;
  }

  .additionalText:before {
    background-color: #002238;
    bottom: 0;
    content: "";
    height: 1px;
    inset-inline-start: 0;
    position: absolute;
    transition: all 0.3s ease-out;
    visibility: hidden;
    width: 0;
  }

  &:hover {
    .additionalText:before {
      visibility: visible;
      width: 100%;
    }

    .additionalArrow {
      padding-inline-start: 5px;
    }
  }

  .additionalArrow {
    display: inline-block;
    margin-inline-start: 0;
    min-width: 15px;
    position: relative;
    top: 1px;
    transition: all 0.3s ease-out;
    cursor: pointer;
    font-weight: 900;
  }
}
