@import "../../../styles/variables.scss";

.row {
  display: flex;
  flex-direction: column;
  margin-left: -15px;
  margin-right: -15px;
  padding-bottom: 30px;

  @include not-mobile {
    flex-direction: row;
  }
}

.column {
  display: flex;
  flex-direction: column;
  flex-basis: 100%;
  flex: 1;
  margin: 15px;
  position: relative;
  overflow: hidden;

  @include not-mobile {
    margin: 0 15px;
  }

  &:hover {
    .rightContent {
      bottom: -10%;
    }
  }
}

.backgroundImage {
  display: block;
  width: 100%;
  height: auto;
  // object-fit: cover;
  aspect-ratio: auto 900 / 900;
}

.tileContent {
  position: absolute;
  inset: 0;
  overflow: hidden;
  display: flex;
}

.leftContent {
  width: 60%;
  margin-bottom: 70px;
  justify-content: flex-end;
  flex-direction: column;
  display: flex;
  margin-left: 30px;

  a {
    text-decoration: none;
  }
}

.header {
  font-size: 25px;
  font-weight: 600;
  font-family: Poppins;
  margin-top: 0;
  margin-bottom: 10px;

  @include tablet {
    font-size: 15px !important;
  }

  @include not-mobile {
    font-size: 40px;
  }
}

.rightContent {
  width: 40%;
  display: flex;
  align-items: flex-end;
  position: relative;
  bottom: -20%;
  transition: bottom 0.3s;

  img {
    width: 100%;
    display: block;
    height: auto;
  }
}

.buttonText {
  min-width: 170px;
}

.button {
  max-width: 190px;
}

.linksContainer {
  display: flex;
  flex-wrap: wrap;
  margin-top: 10px;
  margin-bottom: 15px;
}

.link {
  font-size: 11px;
  letter-spacing: 1.32px;
  color: #002238;
  cursor: pointer;
  font-weight: 900;
  display: inline-block;
  margin-right: 15px;
  padding-top: 10px;
  padding-bottom: 10px;

  @include not-mobile {
    font-size: 13px;
    letter-spacing: 1.56px;
  }

  .additionalText {
    position: relative;
  }

  .additionalText:before {
    background-color: #002238;
    bottom: 0;
    content: "";
    height: 1px;
    inset-inline-start: 0;
    position: absolute;
    transition: all 0.3s ease-out;
    visibility: hidden;
    width: 0;
  }

  &:hover {
    .additionalText:before {
      visibility: visible;
      width: 100%;
    }

    .additionalArrow {
      padding-inline-start: 5px;
    }
  }

  .additionalArrow {
    display: inline-block;
    margin-inline-start: 0;
    min-width: 15px;
    position: relative;
    top: 1px;
    transition: all 0.3s ease-out;
    cursor: pointer;
    font-weight: 900;
  }
}
