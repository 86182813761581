@import "../../../styles/variables.scss";

.header {
  color: #002238;
  font-family: Poppins;
  font-size: 32px;
  font-weight: 600;
  text-align: center;
}

.sliderContainer {
  padding-bottom: 1px;
  position: relative;
  width: 100%;
}