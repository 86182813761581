@import "../../../styles/";

.activeLink {
  @apply bg-primary text-white;
}

.wrapper {
  display: flex;
  justify-content: center;
}

.navContainer {
  display: flex;
  margin: 24px 0;
  border-radius: 9999px;
  background-color: $shade-100;
  min-width: 50%;

  @include mobile {
    justify-content: flex-start;
    width: 100vw;
    overflow-x: scroll;
  }

  @include tablet {
    justify-content: flex-start;
    width: 80vw;
    overflow-x: scroll;
  }
}

.tab {
  flex: 1;
  min-width: max-content;
  white-space: nowrap;
  text-align: center;
  padding: 10.5px 34.5px;
  margin: 4px 5px;
  border-radius: 9999px;
  cursor: pointer;
  font-size: 14px;
  font-weight: 700;
}
