@import "../../../styles/variables.scss";

.row {
  display: flex;
  flex-direction: column;
  width: 100%;
  overflow: hidden;

  @include laptop {
    flex-direction: row;
  }

  @media (max-width: $tablet-max) {
    padding-top: 2rem;
  }
}

.infoBox {
  position: absolute;
  margin-left: 5px;
  margin-top: 2px;
  padding-right: 10px;

  &:hover {
    cursor: pointer;
  }
}

.modal {
  display: none;
  position: absolute;
  z-index: 3;
  border-radius: 6px;
  border: 1px solid var(--Primary-Deep-Blue, #13294b);
  color: var(--Primary-Deep-Blue, #13294b);
  font-family: var(--Font-Family-Primary, Poppins);
  font-size: var(--Font-Size-Body, 15px);
  font-style: normal;
  font-weight: 400;
  line-height: var(--Font-Line-height-Body, 23px); /* 153.333% */
  gap: var(--Font-Paragraph-spacing-Body, 8px);
}

.infoBox:hover .modal {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 321px;
  padding: 16px 19px;
  margin-left: 28px;
  top: -40px;
  overflow-wrap: break-word;
  white-space: normal;
  background: var(--White, #fff);
  box-shadow: 0px 1px 15px 0px rgba(0, 0, 0, 0.1);

  @media (min-width: 530px) and (max-width: 991px) {
    max-width: min-content;
  }
  @media (min-width: 408px) and (max-width: 529px) {
    top: 30px;
    left: -110px;
    max-width: min-content;
  }
  @media (min-width: 364px) and (max-width: 408px) {
    top: 30px;
    left: -110px;
  }
  @media (max-width: 363px) {
    top: 30px;
    left: -110px;
    max-width: min-content;
  }
}

.column {
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex-basis: 100%;
  flex: 1;
  // overflow: hidden;
}

.textContent {
  z-index: 2;
  @include laptop {
    flex: 0 0 41.666667%;
    margin-left: 8.333333%;
  }
}

.header {
  font-size: 23px;
  font-weight: 700;
  font-family: Poppins;
  margin-bottom: 20px;

  @include laptop {
    font-size: 32px;
  }
}

.bodyText {
  color: #002238;
  font-size: 19px;
  font-family: Poppins;
  font-weight: 400;
  width: 85%;
  margin: 0;
}

.periodText {
  position: relative;
  cursor: pointer;
  text-decoration: underline;
  display: inline-block;
  z-index: 2;
}

.periodPopover {
  background-color: #efebe4;
  border: 1px solid #002238;
  position: absolute;
  border-radius: 10px;
  padding: 5px 20px;

  div + div {
    border-top: 1px solid #c1c7c9;
  }
}

.periodPopoverItem {
  text-decoration: none;
  text-align: center;
  padding: 15px 0;

  &:hover {
    color: #005d6e;
  }
}

.imageContent {
  padding: 100px 0;
  justify-content: center;
  align-items: center;
  position: relative;
}

.imageContainer {
  background-size: 100%;
  width: 65%;
  position: relative;

  &::after {
    content: "";
    display: block;
    padding-bottom: 100%;
  }
}

.imageAnimate {
  background-image: url("https://sodastream.com/cdn/shop/files/Ripple_2x_5d53a5bc-6811-45aa-ab56-496c1282ffcd.png");
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: 100%;
  position: absolute;
  width: 100%;
  height: 100%;
}

.imageAnimateFront {
  animation: gimmick-pulse-front 8s linear infinite;
}

.imageAnimateBack {
  animation: gimmick-pulse-back 8s linear infinite;
  opacity: 0;
}

@keyframes gimmick-pulse-front {
  0% {
    opacity: 1;
    transform: scale(1.1);
  }

  25% {
    opacity: 1;
    transform: scale(1.3);
  }

  50% {
    opacity: 0;
    transform: scale(1.5);
  }

  51% {
    opacity: 0;
    transform: scale(0.7);
  }

  75% {
    opacity: 1;
    transform: scale(0.9);
  }

  to {
    opacity: 1;
    transform: scale(1.1);
  }
}

@keyframes gimmick-pulse-back {
  0% {
    opacity: 0;
    transform: scale(0.7);
  }

  25% {
    opacity: 1;
    transform: scale(0.9);
  }

  50% {
    opacity: 1;
    transform: scale(1.1);
  }

  75% {
    opacity: 1;
    transform: scale(1.3);
  }

  to {
    opacity: 0;
    transform: scale(1.5);
  }
}
