@import "../../styles/";

.container {
  h1 {
    font-size: 2em;
    font-weight: 500;
  }

  h2 {
    font-size: 1.5em;
    font-weight: 500;
  }

  h3 {
    font-size: 1.17em;
    font-weight: 500;
  }

  h4 {
    font-size: 1em;
    font-weight: 500;
  }

  h5 {
    font-size: 0.83em;
    font-weight: 500;
  }

  h6 {
    font-size: 0.67em;
    font-weight: 500;
  }

  p {
    font-size: 15px;
    font-weight: 500;
    color: #808080;
  }
}

.structuredTextWrapper {
  background-color: var(--backgroundColor);

  > h1,
  > h2 {
    font-size: var(--headingTextSize);
    font-weight: 600;
  }

  > p {
    font-size: 15px;
    font-weight: 500;
    color: var(--textColor);
  }

  a {
    color: $secondary-deeper-fresh-blue;
    text-decoration: underline;
  }
}
