@import "../../../styles/variables.scss";

.container {
  top: 50%;
  transform: translateY(-50%);
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: -webkit-center;
  cursor: pointer;
  z-index: 5;
}

.right-chevron {
  display: inline-block;
  border-right: 3px solid #212529;
  border-bottom: 3px solid #212529;
  width: 15px;
  height: 15px;
  transform: rotate(-45deg);
  margin-right: 5px;
}

.left-chevron {
  display: inline-block;
  border-right: 3px solid #212529;
  border-bottom: 3px solid #212529;
  width: 15px;
  height: 15px;
  transform: rotate(-225deg);
  margin-left: 5px;
}

.prev {
  left: 0;
}

.next {
  right: 0;
}
