@import "./colors.scss";

$header-promo-height: 58px;
$header-promo-height-mobile: 88px;
$header-height: 90px;
$header-height-mobile: 60px;
$sub-header-height-mobile: 50px;

// BREAKPOINT WIDTHS
// simplified version of https://gist.github.com/gokulkrishh/242e68d1ee94ad05f488
$mobile-min: 320px;
$mobile-max: 767px;
$tablet-min: 768px;
$tablet-max: 1024px;
$laptop-min: 1025px;

// BREAKPOINTS
@mixin mobile {
  @media (min-width: #{$mobile-min}) and (max-width: #{$mobile-max}) {
    @content;
  }
}

@mixin tablet {
  @media (min-width: #{$tablet-min}) and (max-width: #{$tablet-max}) {
    @content;
  }
}

@mixin laptop {
  @media (min-width: #{$laptop-min}) {
    @content;
  }
}

@mixin not-laptop {
  @media (max-width: #{$laptop-min}) {
    @content;
  }
}

@mixin not-mobile {
  @media (min-width: #{$tablet-min}) {
    @content;
  }
}

@mixin desktop-only($display: block) {
  display: none;

  @include laptop {
    display: #{$display};
  }
}

@mixin mobile-only($display: block) {
  display: #{$display};

  @include laptop {
    display: none;
  }
}
