@import "../../../styles/variables.scss";

.subcontainer {
  padding-top: 50px;
  padding-bottom: 50px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 50px;

  @media (max-width: $tablet-max) {
    padding-top: 25px;
    padding-bottom: 25px;
    flex-direction: column;
    gap: 0;
  }
}

.textContainer {
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;

  @media (max-width: $tablet-max) {
    width: 100%;
    margin-right: 0;
  }
}

.title {
  font-size: 32px;
  font-weight: 600;
  color: #002238;
  font-family: Poppins;
  margin-bottom: 0;

  @media (max-width: $tablet-max) {
    font-size: 22px;
    text-align: center;
  }
}

.subtitle {
  font-size: 16px;
  font-weight: 400;
  color: #002238;
  font-family: Poppins;

  @media (max-width: $tablet-max) {
    text-align: center;
  }
}

.checkboxContainer {
  display: flex;
  align-items: flex-start;
  margin-top: 10px;

  input {
    transform: scale(1.5);
    margin-top: 5px;
    cursor: pointer;
    border: none;
  }
  p {
    color: #002238;
    font-size: 11px;
    font-weight: 400;
    font-family: Poppins;
    margin-left: 10px;
    margin-top: 0;
  }

  @media (max-width: $tablet-max) {
    input {
      margin-left: 5px;
    }
  }
}

.hero {
  flex: 1;

  @media (max-width: $tablet-max) {
    width: 100%;
    margin-top: 30px;
  }
}

.inputContainer {
  display: flex;
  position: relative;
  height: 60px;
  margin-bottom: 25px;
  margin-top: 25px;

  input {
    border: none;
    outline: 1px solid #002238;
    border-radius: 93px;
    background-color: white;
    width: 100%;
    padding: 20px 30px;
    color: #002238;
    font-size: 14px;
    font-family: Poppins;
    &::placeholder {
      color: #002238;
    }
    &::-ms-input-placeholder {
      color: #002238;
    }
  }

  button {
    position: absolute;
    right: 0;
    height: 60px;
    width: 216px;

    &:hover {
      background-color: #75a7ad;
    }
  }

  @media (max-width: $tablet-max) {
    flex-direction: column;
    height: auto;

    button {
      width: 100%;
      position: unset;
      margin-top: 5px;
      height: 50px;
      font-size: 11px;
    }

    input {
      height: 50px;
    }
  }
}
