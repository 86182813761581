@import "../../styles/variables.scss";

/* Parent */
.container {
  height: 85vh;
  width: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  display: flex;
}

/* Content holds the Text and Button */
.content {
  display: flex;
  flex-direction: column;
  color: white;
  flex-grow: 1;
  font-family: Poppins;

  padding-top: 15%;
  padding-bottom: 10%;

}

.heading {
  display: inline-block;
  font-size: 45px;
  text-align: left;
  font-weight: 700;
  @media (max-width: $mobile-max) {
    font-size: 30px;
  }
}

.description {
  text-align: left;
  display: inline-block;
}

.button {
  margin-top: 20px;
}

.hide {
  display: none;
}

.hasClickableBackground {
  cursor: pointer;
}

@media (max-width: $mobile-max) {
  .hideMobile {
    display: none;
  }
}

@media (min-width: $tablet-min) {
  .hideMobile {
    display: flex;
  }
}

@media (max-width: $mobile-max) {
  .showMobile {
    display: flex;
  }
}

@media (min-width: $tablet-min) {
  .showMobile {
    display: none;
  }
}

