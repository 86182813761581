@import "../../styles/variables.scss";

.link {
  text-decoration: none;
}

.featuredArticleContainer {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: #efebe4;
  width: 100%;
  flex-grow: 1;
  text-align: center;
}

.summaryContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px;
  & > div {
    height: 10vh;
    display: flex;
    align-items: center;
    & > span {
      font-size: 16px;
      font-weight: 500;
      color: #13294b;
      font-family: Poppins;
      overflow: hidden;
      max-height: 100%;
      line-clamp: 3;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 3;
    }
  }

  @media (max-width: $tablet-max) {
    padding: 15px 10px;
    & > div > h1 {
      font-size: 14px;
      text-overflow: ellipsis;
    }
  }
}

.coverImage {
  width: 100%;
  height: 30vh;
  position: relative;
}
