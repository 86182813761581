@import "../../../styles/variables.scss";

.lowestPrice {
  color: #8b8b8b;
  display: flex;
  flex-direction: column;
  font-family: Poppins;
  font-size: 10px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-bottom: 8px;
  margin-top: 16px;
}

.price {
  color: #002238;
  display: block;
  flex-grow: 1;
  font-size: 15px;
  font-weight: 500;
  margin-top: 15px;
}

.productItem {
  align-items: center;
  box-sizing: border-box;
  color: #000;
  display: flex;
  flex-direction: column;
  height: 100%;
  outline: none;
  padding: 0 10px;
  position: relative;
  text-align: center;
  text-decoration: none;
}

.title {
  box-sizing: content-box;
  color: #002238;
  display: inline-block;
  font-family: "Poppins";
  font-size: 14px;
  font-weight: 400;
  height: 40px;
  padding-bottom: 10px;
  padding-top: 10px;
  text-decoration: none;
  width: 100%;
}