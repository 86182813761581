@import "../../styles/variables.scss";

.header {
  font-family: Poppins;
  font-size: 14px;
  line-height: 21px;
  font-weight: 400;
  color: #13294b;
  text-align: center;
}

.subHeader {
  font-family: Poppins;
  font-size: 32px;
  line-height: 41px;
  color: #13294b;
  font-weight: 500;
  margin-top: 10px;
  margin-bottom: 50px;
  text-align: center;
}

.featuredArticlesContainer {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  gap: 30px;
  margin-top: 20px;

  @media (max-width: $tablet-max) {
    grid-template-columns: 1fr 1fr;
    gap: 15px;
  }
}
