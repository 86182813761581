$primary-deep-blue: #13294b;
$primary-fresh-blue: #75a7ad;
$secondary-fresh-blue: #e3edef;
$secondary-deeper-sand: #e5dcd3;
$secondary-deeper-fresh-blue: #005d6e;

$accent-action: #027b3b;

$shade-100: #f5f5f5;
$shade-200: #e5e5e5;
$shade-300: #cccccc;
$shade-500: #808080;
$shade-600: #666666;
$shade-700: #4e4e4e;
$shade-900: #1a1a1a;

$white: #ffffff;
